import React from "react";
import { Container, CssBaseline, LinearProgress } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Footer } from "./controls/Footer";
import { HomePage } from "./pages/HomePage";
import { RealmAppProvider, useRealmApp } from "../components/RealmApp";
import { ThemeProvider } from "../components/Theme";
import Config from "../shared/Config";
import "./App.css";
import AuthRedirect from "./pages/AuthRedirect";
import { Login } from "./pages/Login";
import { ProfilePage } from "./pages/Profile/ProfilePage";
import { RedirectPage } from "./pages/RedirectPage";
import { AppTopBar } from "./controls/AppTopBar";
import { LoginDialog } from "./controls/LoginDialog";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { EmailConfirmPage } from "./pages/EmailConfirmPage";
import AppDrawer from "./controls/AppDrawer";
import Export from "./pages/Export";
import { About } from "./pages/About";
import Conversation from "../modules/Chat/Conversation";
import ChatPage from "../modules/Chat/Chat";

const { appId } = Config();

function RequireAuth({ children }) {
  const { currentUser } = useRealmApp();
  const location = useLocation();
  if (currentUser?.isLoggedIn === true) {
    return children
  } else {
    return <Navigate to={`/login?redirect=${location.pathname}`} replace />;
  }
}

export default function AppWithRealm() {
  return (
    <RealmAppProvider appId={appId}>
      <ThemeProvider>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </RealmAppProvider>
  );
}

function App() {
  const { currentUser, logOut } = useRealmApp();
  const [showLogin, setShowLogin] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const loading = false;
  const store = { name: 'Elevate', theme: '' };

  return (
    <div sx={{ bgcolor: 'background.default' }}>
      <Router>
        <AppTopBar
          setDrawerOpen={setDrawerOpen}
          store={store}
          currentUser={currentUser}
          setShowLogin={setShowLogin} />
        <AppDrawer
          store={store}
          currentUser={currentUser}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
          setShowLogin={setShowLogin}
          logOut={logOut} />
        {loading ? <LinearProgress /> :
          <Container disableGutters={true}>
            <LoginDialog open={showLogin} setShowLogin={setShowLogin} />
            <Routes>
              <Route path="/" element={<HomePage currentUser={currentUser} />} />
              <Route path="/about" element={<About />} />
              <Route path="/redirect" element={<RedirectPage />} />
              <Route path="/login" element={<Login store={store} />} />
              <Route path="/signup" element={<Login isSignup={true} store={store} />} />
              <Route path="/auth/callback" element={<AuthRedirect />} />
              <Route path="/resetPassword" element={<ResetPasswordPage />} />
              <Route path="/confirm" element={<EmailConfirmPage />} />
              <Route path="/profile" element={<RequireAuth><ProfilePage store={store} currentUser={currentUser} /></RequireAuth>} />
              <Route path="/chat" element={<RequireAuth><ChatPage currentUser={currentUser} /></RequireAuth>} />
              <Route path="/chat/c/:conversationId" element={<RequireAuth><Conversation currentUser={currentUser} /></RequireAuth>} />
              <Route path="/exports" element={<RequireAuth><Export currentUser={currentUser} /></RequireAuth>} />
            </Routes>
            <Footer />
          </Container>}
      </Router>
    </div>
  );
}
