import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";

export function About() {

  return (
    <main>
      <Box sx={{ bgcolor: 'background.paper', pt: 3, pb: 6 }}>
        <Container maxWidth="sm">
          <Typography component="h4"
            variant="h6" align="center" gutterBottom>
            The platform to lorem ipsum...
          </Typography>
          <Typography variant="p" paragraph>
            Elevate is a premier network/marketplace for health services
          </Typography>
          <Typography variant="p" paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center">
          
          </Stack>
        </Container>
      </Box>
    </main>
  );
}
